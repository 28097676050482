import { useForm } from "@mantine/form";
import { useState } from "react";
import { Button, Divider, NumberInput, Space, Stack, TextInput,Text } from "@mantine/core";
import { ThankYouMessage } from "../../components/ThankYouMessage";
import { BondSearch } from "./BondSearch"; 

let submitting = false;
export interface raiderFormDatas {
    bond_number: string;
    email_address: string; 
    total_bond_amount: number;
    increase_amount: number; 
    trustee_name: string; 
    bond_id: string; 
}
export const raiderFormData: raiderFormDatas = {
  bond_number: "",
  email_address: "", 
  total_bond_amount: 0,
  increase_amount: 0, 
  trustee_name: "", 
  bond_id: "", 

}

export const RiderForm = () => {
  const [showingDoneMessage, setShowingDoneMessage] = useState(false); 
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      bond_number: "",
      email_address: "", 
      total_bond_amount: 0,
      increase_amount: 0, 
      trustee_name: "", 
      bond_id: "",
    },
  });
  

  const setPrefillData = (data: any) => {
    //console.log(data); 
    debugger; 
    setLoading(data?.isLoading ? data?.isLoading : false);
    form.setValues({
        trustee_name : data?.trusteeName,  
        total_bond_amount : data?.newTotalBondAmount,
        bond_id: data?.bondId,
        email_address: data?.email,
        bond_number: form.getInputProps("bond_number").value,
        increase_amount: data?.increaseAmount,
    })
  }
  const handleSubmit = async () => {
    // if (submitting) return;
    submitting = true; 
     
    console.log(form.getValues())
    //loading 
    setLoading(true);
    //http://localhost:8000 
    fetch("https://api.insurancemastersinc.com/submission/submitRiderBond", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Ocd-Target": "SubmitRider",
      },
      body: JSON.stringify({
        ...form.getValues()
      }),
    }).then((res) => {
      if(res.status === 200){
        setShowingDoneMessage(true);
        form.reset();
        submitting = false;
        setLoading(false);
      }else{
        submitting = false;
        setLoading(false);
        console.log(res);
      }
    });

  };

  if (showingDoneMessage ) {
    return <ThankYouMessage setShowingDoneMessage={setShowingDoneMessage} />;
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Text fz="30px">
          Rider Submission Request
        </Text>
        <Divider m="30px" />
        <Space h="50px" />
        
        <TextInput
          label="Enter the Bond Number for the Rider"
          required
          {...form.getInputProps("bond_number")}
        />
 
        <BondSearch
            onRiderChange={setPrefillData}
            bondNumber={form.getInputProps("bond_number").value} 
        />
 
        <Button fullWidth type="submit" loading={loading} mt="20px">
          Submit Rider
        </Button> 
    </form>
  );
};
