import { useEffect, useRef, useState } from "react";
import { Stack, TextInput, Text, Card, Divider, Group, NumberInput, Loader } from "@mantine/core";
import { searchBondByBondNoAndEmail } from "../../Protocol";
import { IconAlertCircle, IconCircleCheck, IconMail } from "@tabler/icons-react";

interface PrefillData {
  trusteeName: string;
  fileNumber: string;
  debtorName: string;
  creditorName: string;
  bondAmount: number;
  newTotalBondAmount: number;
  bondId: string;
  email: string;
  increaseAmount: number;
  loading: boolean;
}

 

export const BondSearch = ({ bondNumber, onRiderChange }: { bondNumber: string, onRiderChange: any }) => {
  const [prefillData, setPrefillData] = useState<PrefillData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [increaseValue, setIncreaseValue] = useState<number>(0);
  const [emailValid, setEmailValid] = useState(false);
  // const increaseAmount = useRef<HTMLInputElement>(null); 
  useEffect(() => { 
    if(bondNumber.trim() === "" || bondNumber.length <10){
      onRiderChange({isLoading: false  })
      setPrefillData(null); 
      return;
    }

    if (!email || email.trim() === "") {
    //   setEmailError(false);
      setEmailValid(false);
    //   searching_email = false;
      return;
    }
  
    const debounceTimeout = setTimeout(() => {
        fetchPrefillData();
    }, 500);
  
    return () => {
      clearTimeout(debounceTimeout); 
    };
  }, [email,bondNumber]);


  useEffect(() => { 
    if (prefillData) {
      setIncreaseValue(prefillData.newTotalBondAmount - prefillData.bondAmount);
        // increaseAmount.current!.value = (prefillData.newTotalBondAmount - prefillData.bondAmount).toString();
    }
  }, [prefillData]);

  const fetchPrefillData = async () => {
    
    setPrefillData(null);
    setError(null);
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setEmailValid(false); 
        setError("Please enter a valid email address.");
        return;
    }
 
    try {
      onRiderChange({isLoading: true })
      setError(null); 
      const response = await searchBondByBondNoAndEmail(bondNumber.trim(), email.trim());
      if (response.success) {
        const data = response.data
        if(data && data.isRider){
          onRiderChange({isLoading: false  })
          setError("Bond is already a Rider. Contact Insurance Masters, Inc. 410.971.5869.");
          return;
        }
         
        setPrefillData({...data, email: email,newTotalBondAmount : data.bondAmount});
        onRiderChange({...data, email: email,newTotalBondAmount : data.bondAmount, isLoading: false});
        setEmailValid(true);
        console.log(data);
      } else {
        setEmailValid(false);
        setPrefillData(null); 
        setError(response.message);
        onRiderChange({isLoading: false  }) 
        return;
      }
    }catch (err) {
      setPrefillData(null); 
      setEmailValid(false);
      setError("Error fetching prefill data");
      onRiderChange({isLoading: false  }) 
      console.error(err);
      return;
    }  
  };
  
  return (
    <Stack>
        
      <Card>
        <Text>Please enter your email</Text>
        <Divider />

        <TextInput
          required
          leftSection={<IconMail />}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        //   error={error ? error : undefined}
        />

        {emailValid && (
          <Group>
            <IconCircleCheck color="green" />
            <Text fz="xs">We found your information in our system.</Text>
          </Group>
        )}
        {!emailValid && error && (
            <Group>
                <IconAlertCircle color="red" />
                <Text fz="xs">{error}</Text>
            </Group>
        )}
        <Divider />
      </Card> 
      
      {prefillData && (
        <Card>
             <Group>
                <NumberInput  hideControls label="New Total Bond Amount" min={prefillData.bondAmount} value={prefillData.newTotalBondAmount} required 
                    onChange={(e) => {
                        debugger; 
                        setPrefillData({ ...prefillData, newTotalBondAmount: Number(e) })
                        onRiderChange({ ...prefillData, newTotalBondAmount: Number(e) })
                        } 
                    }   
                    />  
                <NumberInput  label="Increase Amount" min={0} value={increaseValue} readOnly styles={{input: { backgroundColor: "white", color: "black", cursor: "not-allowed" }}}/>
                <NumberInput label="Original Bond Amount" value={prefillData.bondAmount} readOnly styles={{input: { backgroundColor: "white", color: "black", cursor: "not-allowed" }}} />
            </Group>
            <TextInput
                label="Trustee Name (Check the accuracy of the trustees for this bond and change if necessary)"
                value={prefillData.trusteeName}
                onChange={(e) => {
                    debugger;
                    setPrefillData({ ...prefillData, trusteeName: e.currentTarget.value })
                    onRiderChange({ ...prefillData, trusteeName: e.currentTarget.value })
                    }
                }
            />
            <TextInput label="File #" value={prefillData.fileNumber} readOnly styles={{input: { backgroundColor: "white", color: "black", cursor: "not-allowed" }}}  />
            <TextInput label="Debtor" value={prefillData.debtorName} readOnly styles={{input: { backgroundColor: "white", color: "black", cursor: "not-allowed" }}}  />
            <TextInput label="Creditor" value={prefillData.creditorName} readOnly styles={{input: { backgroundColor: "white", color: "black", cursor: "not-allowed" }}}  />
        </Card>
      )}
    </Stack>
  );
};

